<template>
  <section class="m-check-phone m-scroll">

    <!-- 头部导航 -->
    <m-header title="绑定新手机号"></m-header>

    <p class="title">绑定新号码</p>

    <div class="input-cell">
      <input v-model="phone" type="text" placeholder="输入新的号码">
      <div class="phone-type" @click="telPicker = true">
        <span>+{{phoneType}}</span>
        <img src="@/assets/public/icon-fanhui@2x.png" alt="icon">
      </div>
    </div>



    <div class="input-cell">
      <input v-model="code" type="text" placeholder="请输入手机验证码">
      <div class="get-code">
        <span v-show="!getCode" @click="onSend" class="get">获取验证码</span>
        <span v-show="getCode"  class="seconds">{{seconds}}S</span>
      </div>
    </div>
  
    <div class="footer">
      <van-button @click="onNext" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">下一步</van-button>
    </div>


    <van-action-sheet v-model="telPicker" :actions="actions" cancel-text="取消" close-on-click-action @cancel="telPicker = false" @select="handlePhonetype" />





    

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { bindPhone } from '@/api/zh/mine.js'
import { phone_code } from '@/api/zh/login'
export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      phone:'',
      phoneType:'1',
      code:'',
      model:'',
      getCode:false,
      seconds:60,
      telPicker:false,
      actions: [{ name: '1' }, { name: '86' },],
    }
  },

  

  methods:{

    // 选择区号
    handlePhonetype(it){
      this.phoneType = it.name
      this.telPicker = false
    },
    onSend(){
      let params = {};
      params.phone = this.phone;
      params.area_code = this.phoneType;
      params.model = this.model;
      this.$store.commit("load");
      phone_code(params)
        .then((res) => {
          if (res) {
            this.$notify({ type: "success", message: "发送成功" });
            this.getCode = true;
             const timer = setInterval(() => {
            if(this.seconds < 1){
              this.getCode = false
              this.seconds = 60
              clearInterval(timer)
            }
            this.seconds -- 
          }, 1000);
          }
        })
        .finally(() => {
          this.$store.commit("unload");
        });
    },
    onNext(){
      let params = {}

      if(this.phone == ''){
        this.$notify({ type: 'warning', message: '请完善手机号'})
        return false
      }else {
        params.phone = this.phone
      }
      params.area_code = this.phoneType;
      if(this.code == ''){
        this.$notify({ type: 'warning', message: '请输入验证码'})
        return false
      }else {
        params.code = this.code
      }

      bindPhone(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: '手机号绑定成功'})
          this.$router.go(-1)
        } 
      })
    },

    

   
  }
}
</script>

<style lang="less" scoped>
.m-check-phone {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top: 44px;
  .title {
    margin: 30px 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding-left: 20px;
  }
  .input-cell {
    background: #fff;
    padding:0 20px;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 52px;
    >input {
      outline: none;
      border: none;
      font-size: 14px;
    }
    .phone-type {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      >span {
        font-size:14px;
        font-weight:400;
        color:rgba(51,51,51,1);
        line-height:20px;
      }
      >img {
        width: 20px;
        height: 20px;
        transform:rotate(90deg) ;
        margin-left: 5px;
      }
    }
    .get-code {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        padding: 5px 8px;
        border: 1px solid #3284FA;
        color: #3284FA;
        font-size: 14px;
        font-weight: 400;
        border-radius: 5px;
      }
    }
  }
  .footer {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 38px;
  }
}
</style>